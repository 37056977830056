<template>
  <section>
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <h6>Função: {{permissaoInfo.funcao.nome}}</h6>


          <div class="col-md-12"></div>

          <div class="field col-12 md:col-12" style="margin-bottom:20px;">
            <label for="sel1" style="padding-top:15px">Selecione as permissões:</label>
            <div class="col-md-12" v-for="n in permissaoALL" :key="n"  >
              <label class="form-check-label">
                <input type="checkbox" v-model="permissaoInfo.permissoes" class="form-check-input" :value="n" style="margin-right: 10px;" >
                {{n.nome}}
              </label>
            </div>
          </div>

        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger"  @click="this.$router.push({ name: 'permissao' })" icon="pi pi-angle-left" iconPos="left" />

          <pm-Button  v-if="permissaoInfo.permissoes.length > 0 && permissaoInfo.funcao != ''"  class="p-button-success"
            @click="editar"
            label="Editar"
            icon="pi pi-check" iconPos="right"
          />
        </div>
      </template>
    </pm-Card>

  </section>
</template>


<script>
import { defineComponent } from "vue";
import { Funcao } from "@/class/funcao";
import { PermissaoModulos } from "@/class/PermissaoModulos";
import { Permissoes } from "@/class/permissoes";
import axios from 'axios';



export default defineComponent({
  props: {
    idPermissao:{
    },
  },
  data() {
    return {
      permissaoALL: [],
      funcaoALL:[],
      permissaoInfo: {
        funcao:'',
        permissoes:[],
      },
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Adicionar nova permissão'},
      ],
    };
  },
  computed: {
    pages() {

    },
  },
  methods: {
    async registar() {
      try {
        const data = await Permissoes.cadastrar(this.permissaoInfo);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({ name: "permissao" });

      }catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;
          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;
          setTimeout(() => {
            this.$vaToast.init({
              message: a,
              iconClass: 'fa-star-o',
              position: 'bottom-left',
              duration: 3500,
              fullWidth: false,
              color: 'danger'
            });
          }, 500);
        }
      }
    },
    async editBuscar(id) {
        const data = await Permissoes.obtemUm(id);
        // pegar informacoes da funcao cadastrada
        for (let i = 0; i < this.funcaoALL.length; i++) {
          if (this.funcaoALL[i].id == id) {
            this.permissaoInfo.funcao = this.funcaoALL[i];
          }
        }
        //receber modulos da funcao
        for (const el of data.data.modulos) {
            this.permissaoInfo.permissoes.push(el.modulo);
        }
    },
    async buscarModulos() {
        this.permissaoALL = [];
        const data = await PermissaoModulos.obtemTodos();
        this.permissaoALL = data.data;
    },
    async buscarFuncao() {
        this.funcaoALL = [];
        const data = await Funcao.obtemTodos();
        for (const func of data.data) {
          if (func.id != 1) {
            this.funcaoALL.push(func);
          }
        }
    },
    async editar() {
      try {
        const data = await Permissoes.alterar(this.permissaoInfo);
        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.$router.push({ name: "permissao" });

      }catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;
          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;
          setTimeout(() => {
            this.$vaToast.init({
              message: a,
              iconClass: 'fa-star-o',
              position: 'bottom-left',
              duration: 3500,
              fullWidth: false,
              color: 'danger'
            });
          }, 500);
        }
      }
    }
  },
  async beforeMount() {
    await  this.buscarFuncao();
    await  this.editBuscar(this.idPermissao);
    this.buscarModulos();
  },
});
</script>


<style lang="scss" scoped>

</style>
